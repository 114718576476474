import ReactPiwik from 'react-piwik';
import { TrackingCategories } from '@visikon/core-models/tracking';
import { EnvironmentName } from '@visikon/backbone/src/env';
import { backbone } from '@visikon/backbone/src';
import { ClientInfo, ProgramInfo, UserInfo } from '@visikon/backbone/src/models';
import { CustomDimensions } from './CustomDimensions';

function trackingSiteId(env: EnvironmentName) {
  switch (env) {
    case 'prod':
      return 4;
    default:
      return 5;
  }
}

export class MatomoTracker {
  private env: string | undefined;
  private client: ClientInfo | undefined;
  private user: UserInfo | undefined;
  private language: string | undefined;
  private deviceId: string | undefined;
  private program: ProgramInfo | undefined;

  constructor() {
    backbone.store.subscribe((state) => {
      if (state.env && state.env !== this.env) {
        this.env = state.env;

        new ReactPiwik({
          url: 'https://matomo.visikon.com',
          siteId: trackingSiteId(state.env),
          trackDocumentTitle: false,
        });
        ReactPiwik.push(['enableHeartBeatTimer']);
      }
      if (state.client && state.client !== this.client) {
        this.client = state.client;
        ReactPiwik.push(['setCustomDimension', CustomDimensions.APP_VERSION, state.client.version]);
        ReactPiwik.push(['setCustomDimension', CustomDimensions.CLIENT_TYPE, state.client.type]);
      }

      if (state.user?._id && state.user !== this.user) {
        this.user = state.user;

        ReactPiwik.push(['setUserId', state.user._id]);
      }

      if (state.country?.languageCode && state.country.languageCode !== this.language) {
        this.language = state.country.language;

        ReactPiwik.push(['setCustomDimension', CustomDimensions.LANGUAGE, this.language]);
      }

      if (state.deviceId && state.deviceId !== this.deviceId) {
        this.deviceId = state.deviceId;

        ReactPiwik.push(['setCustomVariable', 1, 'Direct access link visitor', state.deviceId, 'visit']);
      }

      if (state.program && state.program !== this.program) {
        const { id, name } = state.program;
        this.program = state.program;

        ReactPiwik.push(['setCustomDimension', CustomDimensions.PROGRAM, `${name}-${id}`]);
        ReactPiwik.push(['setCustomDimension', CustomDimensions.DEPARTMENT, state.program.department]);
        ReactPiwik.push(['setCustomDimension', CustomDimensions.ORGANIZATION, state.program.organization]);
      }
    });
  }

  public trackScreen(screenName: string) {
    this.trackAction(`Screen / ${screenName}`);
  }

  public trackAction(action: string) {
    ReactPiwik.push(['setDocumentTitle', `${action}`]);
    ReactPiwik.push(['setCustomUrl', `app:${action}`]);
    ReactPiwik.push(['trackPageView']);
    console.log('Track action:', action);
  }

  public trackEvent(category: TrackingCategories, action: string, name?: string, value?: string) {
    ReactPiwik.push(['trackEvent', category, action, name, value]);
    console.log('Track event:', category, action);
  }

  public trackMediaElement(media: HTMLVideoElement | HTMLAudioElement) {
    // Apparently the media element is not detected if supplied directly
    // Therefore, we supply the parent element instead
    ReactPiwik.push(['MediaAnalytics::scanForMedia', media.parentElement]);
  }
}
